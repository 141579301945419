import {
  createFormBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';

export enum RtInterstitialTypeId {
  DIAMOND = 'Interstitial:Diamond',
  GREENTEXTSHADOW = 'Interstitial:GreenTextShadow',
  ILLUSTRATIONTEXT = 'Interstitial:IllustrationText',
  PHOTOGRAPHY = 'Interstitial:Photography',
  TEXTONLY = 'Interstitial:TextOnly'
}

export type MethodType = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface RtInterstitialData {
  user_block?: boolean;
  cancelable?: boolean;
  id: string;
  type: RtInterstitialTypeId;
  illustration?: string;
  headline: string;
  body: string;
  lead_body?: string;
  after_body?: string;
  cta_label: string;
  cta_label_key: string;
  disclosure?: string;
  suppress_label?: string;
  suppress_label_key?: string;
  note?: {
    heading: string;
    body: string;
    background_color?: string;
  };
  supress_action?: {
    href: string;
    target?: string;
  };
  cta_action: {
    href: string;
    target?: string;
  };
  api_action?: {
    url: string;
    method: MethodType;
    default?: string;
    alternative_actions?: {
      [name: string]: {
        url: string;
        method: MethodType;
      };
    };
  };
}

export interface InterstitialWidget {
  id: string;
  data: any;
  suppressible: boolean;
}

export interface InterstitialResponse {
  widgets: InterstitialWidget[];
}

export const createGetInterstitialRequest = (): RequestConfig<
  InterstitialResponse
> => ({
  method: 'GET',
  url: '/v1/interstitials?count=1&insertion_point=oac_dashboard'
});

export const createInterstitialViewRequest = ({
  interstitialName
}: {
  interstitialName: string;
}): RequestConfig<unknown> =>
  createFormBodyRequestConfig({
    url: `/v1/interstitials/${interstitialName}/view`,
    method: 'POST',
    mutating: false,
    opts: { beacon: true },
    json: {
      trigger: 'interstitial'
    }
  });

export const createInterstitialRespondRequest = ({
  interstitialName,
  action
}: {
  interstitialName: string;
  action: string;
}): RequestConfig<unknown> =>
  createFormBodyRequestConfig({
    url: `/v1/interstitials/${interstitialName}/respond`,
    method: 'POST',
    mutating: false,
    opts: { beacon: true },
    json: {
      response: action
    }
  });

export const createInterstitialDeleteRequest = ({
  interstitialName
}: {
  interstitialName: string;
}): RequestConfig<unknown> =>
  createFormBodyRequestConfig({
    url: `/v1/interstitials/${interstitialName}`,
    method: 'DELETE',
    mutating: false,
    opts: { beacon: true },
    json: {}
  });

export const createCtaActionRequest = ({
  url,
  method
}: {
  url: string;
  method: MethodType;
}): RequestConfig => {
  return {
    method: method,
    url: url,
    mutating: false,
    opts: { beacon: true }
  };
};
